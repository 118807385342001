<template>
    <div class="clientcabin clientcabin-blog">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/1.png"
                        width="100%"
                    />
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        Roofing Company Marketing Strategy & Branding Plan Puts
                        Texas Contractor On Page 1 Of Google in 3 Months
                    </p>
                    <v-card
                        class="blueish black--text px-6 py-5 rounded-lg my-6 elevation-0"
                    >
                        <p class="font-weight-bold">Key Takeaways</p>
                        <ul>
                            <li>
                                Our campaigns helped a roofer in a very
                                competitive market secure first-page Google
                                positions, in just 3 months;
                            </li>
                            <li>
                                Our client went from virtually invisible to
                                being one of the most prominent companies in
                                their target area;
                            </li>
                            <li>
                                To achieve this we focused on more than just
                                content - we also helped our client with their
                                GMB;
                            </li>
                            <li>
                                The company continues to see great results using
                                our services.
                            </li>
                        </ul>
                    </v-card>
                    <p>
                        The roofing business is a major industry in the Dallas
                        area, and we knew that we had to give our client an edge
                        over their competition.
                    </p>
                    <p class="text-h4 mt-8 text-center">How We Did It</p>
                    <p>
                        We spent months focused on this, making sure they were
                        fully satisfied with our work and that we had a good
                        relationship with them before moving on.
                    </p>
                    <p>
                        We created a Google My Business page for their business,
                        even before starting the content campaigns.
                    </p>
                    <p>
                        And after a few months of campaigns running on our
                        platform, we saw that the roofing business had started
                        to become visible online and has improved its exposure
                        in its local area — turning the report from red (no
                        visibility/ nowhere to be found on page 1) to green (the
                        business has secured multiple rankings on page 1).
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Online Visibility Before Content Campaigns
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Online Visibility After The First Campaigns
                    </p>
                    <v-img
                        class="rounded-lg mt-10"
                        src="@/assets/img/clientcabin/stories/roofing-company/4.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Updated snapshot report for the client's online
                        visibility and exposure, one year after the first
                        campaign: 23 campaigns were published during this
                        period.
                    </p>
                    <p>
                        The snapshot reports above illustrate the business
                        visibility before and after the campaigns. They also
                        indicate the targeted areas and the services that the
                        roofing contractor offers.
                    </p>
                    <p>
                        And here are some of the page 1 rankings that our
                        campaigns secured for the roofing company for important
                        keywords for the business relevant to the services it
                        provides and the location it serves:
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/5.png"
                        width="100%"
                    />
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/6.png"
                        width="100%"
                    />
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/7.png"
                        width="100%"
                    />
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/8.png"
                        width="100%"
                    />
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/9.png"
                        width="100%"
                    />
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/roofing-company/10.png"
                        width="100%"
                    />
                    <p class="text-h4 mt-8 text-center">
                        Using Hyper-Targeted Content Marketing Campaigns To
                        Stand Out In a Crowded Market
                    </p>
                    <p>
                        In this case study, we see an example of how content
                        marketing can be used to improve a company’s visibility
                        online in key target local areas.
                    </p>
                    <p>
                        A small company in a competitive industry needed help
                        getting their name out there, and we were able to
                        provide the tools necessary for them to succeed.
                    </p>
                    <p>
                        The client ended up getting their entire website rebuilt
                        and they are now experiencing growth like never before
                        thanks to our approach.
                    </p>
                    <p>
                        Our client attributes their success largely to the
                        business commitment to a strong content marketing
                        strategy.
                    </p>
                    <p>
                        They continue to work with us to improve their exposure
                        in other larger areas as well, which will allow them to
                        grow their business even further!
                    </p>
                    <p>
                        We are confident that you will find our powerful content
                        amplification engine unmatched to any other marketing
                        solution on the market.
                    </p>
                    <p>
                        If you’re looking for a way to take your content to the
                        next level, our company is an easy choice. We know you
                        want your content marketing strategy to be effective,
                        and we believe that this is the solution that will help
                        you get there.
                    </p>

                    <p>
                        <router-link to="/info" class="font-weight-bold">
                            Book an appointment today
                        </router-link>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class RoofingCompany extends Vue {}
</script>
